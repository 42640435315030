<!--
 * @Description: 客服服务质检详情
 * @Author: ChenXueLin
 * @Date: 2021-09-22 11:11:56
 * @LastEditTime: 2022-07-28 17:46:13
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      :element-loading-background="loadingBackground"
      v-loading="loading"
    >
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <detail-title-content
            :btnType="btnType"
            :btnText="'质检状态:' + baseInfoForm.qualityStatus"
          >
            <template v-slot:leftButtons>
              <div
                :class="[
                  { yellowBtn: baseInfoForm.reviewStatusCode == 1 },
                  {
                    greenBtn:
                      baseInfoForm.reviewStatusCode == 2 ||
                      baseInfoForm.reviewStatusCode == 3
                  },
                  'approveStatus'
                ]"
              >
                审核状态:{{ baseInfoForm.reviewStatus }}
              </div>
            </template>
          </detail-title-content>
          <div class="baseInfo-content-wrap">
            <div class="detailInfo-content">
              <!-- 基本信息 start -->
              <div class="baseInfo-box">
                <div class="edit-title">基本信息</div>
                <el-form
                  label-width="140px"
                  :model="baseInfoForm"
                  class="editForm mbForm"
                >
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="质检ID" prop="inspectionId">
                        {{ baseInfoForm.inspectionId }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="质检员" prop="qualityPeopleName">
                        {{ baseInfoForm.qualityPeopleName }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="质检时间" prop="qualityTime">
                        {{ baseInfoForm.qualityTime }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="审核人" prop="reviewPeopleName">
                        {{ baseInfoForm.reviewPeopleName }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="审核时间" prop="reviewTimeString">
                        {{ baseInfoForm.reviewTimeString }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
              <!-- 服务信息 -->
              <div class="baseInfo-box">
                <el-tabs v-model="activeName">
                  <el-tab-pane label="服务信息" name="one">
                    <el-form
                      label-width="140px"
                      class="editForm"
                      :model="baseInfoForm"
                    >
                      <el-row>
                        <el-col :span="8">
                          <el-form-item label="客户名称" prop="customerName">
                            {{ baseInfoForm.customerName }}
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item
                            label="来去电人名称"
                            prop="phoneCallName"
                          >
                            {{ baseInfoForm.phoneCallName }}
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item label="来去电号码" prop="phoneNumber">
                            {{ baseInfoForm.phoneNumber }}
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item label="来电类型" prop="phoneType">
                            {{ baseInfoForm.phoneType }}
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item label="通话时长" prop="callDuration">
                            {{ baseInfoForm.callDuration }}
                            <el-button type="text" @click="playVideo"
                              >播放录音</el-button
                            >
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </el-form>
                    <el-form
                      label-width="140px"
                      :model="baseInfoForm"
                      class="mbForm"
                    >
                      <el-row>
                        <el-col :span="8">
                          <el-form-item label="一级服务类型" prop="firstServer">
                            {{ baseInfoForm.firstServer }}
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item
                            label="二级服务类型"
                            prop="secondServer"
                          >
                            {{ baseInfoForm.secondServer }}
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item label="三级服务类型" prop="thirdServer">
                            {{ baseInfoForm.thirdServer }}
                          </el-form-item>
                        </el-col>
                        <el-col :span="24">
                          <el-form-item label="服务内容" prop="serverDetails">
                            {{ baseInfoForm.serverDetails }}
                          </el-form-item>
                        </el-col>
                        <el-col :span="24">
                          <el-form-item label="场景名称" prop="sceneNames">
                            {{ baseInfoForm.scenarioNames.join("，") }}
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </el-form>
                  </el-tab-pane>
                  <el-tab-pane
                    label="关联单据"
                    name="two"
                    v-if="baseInfoForm.taskId"
                  >
                    <tree-chart :json="jsonData" />
                  </el-tab-pane>
                </el-tabs>
              </div>
              <template>
                <div class="edit-title">质检评分</div>
                <div class="score-data">
                  <div class="left-tree">
                    <div class="tree-title">质检标准:</div>
                    <e6-tree
                      v-model="inspectionProject"
                      :list="treeData"
                      :is-check="true"
                      :is-open="true"
                      issingleleaf
                      ref="e6tree1"
                      :selectAndCheck="true"
                      @chkfunc="handleChk"
                    >
                    </e6-tree>
                  </div>
                  <div class="right-tree">
                    <div class="tree-title">已选中:</div>
                    <div
                      class="item-tree"
                      v-for="item in inspectionConfigs"
                      :key="item.configId"
                    >
                      {{ item.configValue }}
                    </div>
                  </div>
                </div>
                <el-form
                  label-width="140px"
                  style="margin-top:40px;"
                  class="inspectionForm"
                  :model="inspectionForm"
                  :rules="inspectionFormRules"
                  ref="inspectionForm"
                >
                  <el-form-item label="质检结果" prop="qualityStatusCode">
                    <e6-vr-select
                      v-model="inspectionForm.qualityStatusCode"
                      :disabled="
                        baseInfoForm.reviewStatusCode == 2 ||
                          baseInfoForm.reviewStatusCode == 3
                      "
                      :data="QTResultList"
                      placeholder="质检结果"
                      title="质检结果"
                      clearable
                      :props="{
                        id: 'codeValue',
                        label: 'codeName'
                      }"
                    ></e6-vr-select>
                  </el-form-item>
                  <el-form-item label="质检建议" prop="qualityAdvice">
                    <el-input
                      :disabled="
                        baseInfoForm.reviewStatusCode == 2 ||
                          baseInfoForm.reviewStatusCode == 3
                      "
                      type="textarea"
                      v-model="inspectionForm.qualityAdvice"
                    ></el-input>
                  </el-form-item>
                </el-form>
              </template>
              <!-- 已质检才显示 -->
              <div
                v-if="
                  baseInfoForm.qualityStatusCode == 2 ||
                    baseInfoForm.qualityStatusCode == 3
                "
              >
                <div class="edit-title">审核评分</div>
                <el-form
                  label-width="140px"
                  style="margin-top:20px;"
                  class="inspectionForm"
                  :model="approveForm"
                  :rules="approveFormRules"
                  ref="approveForm"
                >
                  <el-form-item label="审核结果" prop="reviewStatusCode">
                    <e6-vr-select
                      :disabled="
                        baseInfoForm.reviewStatusCode == 2 ||
                          baseInfoForm.reviewStatusCode == 3
                      "
                      v-model="approveForm.reviewStatusCode"
                      :data="approveResultList"
                      placeholder="审核结果"
                      title="审核结果"
                      clearable
                      :props="{
                        id: 'codeValue',
                        label: 'codeName'
                      }"
                    ></e6-vr-select>
                  </el-form-item>

                  <el-form-item label="审核建议" prop="reviewAdvice">
                    <el-input
                      :disabled="
                        baseInfoForm.reviewStatusCode == 2 ||
                          baseInfoForm.reviewStatusCode == 3
                      "
                      type="textarea"
                      v-model="approveForm.reviewAdvice"
                    ></el-input>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>
        </div>
        <div
          class="edit-wrapper__footer"
          v-if="baseInfoForm.reviewStatusCode == 1"
        >
          <el-button class="cancel" @click="handleGoBack">取消</el-button>
          <el-button
            type="primary"
            @click="confirm"
            v-if="
              (baseInfoForm.qualityStatusCode == 2 ||
                baseInfoForm.qualityStatusCode == 3) &&
                $route.meta.operationIds.includes(136)
            "
            >确定审核</el-button
          >
          <el-button
            type="primary"
            @click="confirm"
            v-if="
              baseInfoForm.qualityStatusCode == 1 &&
                $route.meta.operationIds.includes(73)
            "
            >确定质检</el-button
          >
        </div>
      </section>
    </div>
    <play-video
      :videoId="baseInfoForm.record"
      :dialogVisible="dialogVisible"
      @handleClose="handleClose"
    ></play-video>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import detailTitleContent from "@/components/detailTitleContent";
import { printError } from "@/utils/util";
import PlayVideo from "@/components/playVideo.vue";
import goBack from "@/mixins/goBack";

import {
  getCustomerServiceQTDetail,
  associatedBills,
  getInspection,
  findDownList,
  confirmServiceInspection,
  confirmAuditInspection
} from "@/api";
import TreeChart from "@/components/treeChart";
export default {
  name: "customerServiceQualityDetail",
  components: {
    detailTitleContent,
    TreeChart,
    PlayVideo
  },
  data() {
    return {
      activeName: "one",
      jsonData: [],
      treeData: [],
      inspectionConfigs: [],
      deleteArr: [],
      inspectionForm: {
        qualityStatusCode: "", //质检结果
        qualityAdvice: "" //质检建议
      }, //确认质检form
      inspectionFormRules: {
        qualityStatusCode: [
          {
            required: true,
            message: "请选择质检结果",
            trigger: ["blur", "change"]
          }
        ]
      },
      //审核结果
      approveForm: {
        reviewStatusCode: "", //审核结果
        reviewAdvice: "" //审核建议
      },
      approveFormRules: {
        reviewStatusCode: [
          {
            required: true,
            message: "请选择审核结果",
            trigger: ["blur", "change"]
          }
        ]
      },
      QTResultList: [],
      inspectionProject: [],
      baseInfoForm: {
        scenarioNames: []
      },
      dialogVisible: false
    };
  },
  mixins: [base, listPage, listPageReszie, goBack],
  computed: {
    btnType() {
      let value;
      if (this.baseInfoForm.qualityStatusCode == 1) {
        value = "blueBtn";
      }
      if (
        this.baseInfoForm.qualityStatusCode == 2 ||
        this.baseInfoForm.qualityStatusCode == 3
      ) {
        value = "greenBtn";
      }
      return value;
    }
  },
  watch: {},
  created() {
    this.initForm();
  },
  activated() {
    this.handleRefreshUpdatePage();
  },
  methods: {
    initForm() {
      let hasParamsRoute = this.getHasParamsRoute();
      this.inspectionId = hasParamsRoute.params.id;
      if (this.inspectionId) {
        this.getInspectionReq();
      }
      this.initData();
    },
    async initData() {
      try {
        this.selectLoading = true;
        let promiseList = [findDownList(["QTResult", "ApproveResult"])];
        let [statusRes] = await Promise.all(promiseList);
        //质检结果
        this.QTResultList = this.getFreezeResponse(statusRes, {
          path: "data.QTResult"
        });
        //审核结果
        this.approveResultList = this.getFreezeResponse(statusRes, {
          path: "data.ApproveResult"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    handleChk(node, isCheck) {
      if (isCheck) {
        this.setSelectTree([node]);
      } else {
        this.deleteData([node]);
      }
    },
    setSelectTree(data) {
      data.map(item => {
        let obj = {};
        obj.configId = item.configId;
        obj.configValue = item.configValue;

        if (
          !this.inspectionConfigs.some(each => {
            return each.configId == item.configId;
          }) &&
          !item.children.length
        ) {
          this.inspectionConfigs.push(obj);
        }
        // else {
        //   this.deleteArr.push(item.configId);
        //   this.deleteTree();
        // }
        if (item.children && item.children.length) {
          this.setSelectTree(item.children);
        }
      });
    },
    deleteData(data) {
      data.map(item => {
        this.deleteArr.push(item.configId);
        this.deleteTree();
        if (item.children && item.children.length) {
          this.deleteData(item.children);
        }
      });
    },
    deleteTree() {
      let inspectionConfigs = this.inspectionConfigs;
      let new_arr = inspectionConfigs.filter(
        x => !this.deleteArr.some(item => x.configId == item)
      );
      this.inspectionConfigs = new_arr;
      this.deleteArr = [];
    },
    //查询服务单质检详情
    async getDetail() {
      try {
        this.loading = true;
        let res = await getCustomerServiceQTDetail({
          id: this.inspectionId
        });
        this.baseInfoForm = res.data;
        // 质检信息回显
        let form = this.baseInfoForm;
        this.inspectionForm.qualityStatusCode =
          form.qualityStatusCode && form.qualityStatusCode != 1
            ? form.qualityStatusCode.toString()
            : ""; //质检结果
        this.inspectionForm.qualityAdvice = form.qualityAdvice; //质检建议
        // 审核信息回显
        this.approveForm.reviewStatusCode =
          form.reviewStatusCode && form.reviewStatusCode != 1
            ? form.reviewStatusCode.toString()
            : ""; //审核状态
        this.approveForm.reviewAdvice = form.reviewAdvice; //审核建议
        this.approveForm.versionNumber = form.versionNumber;
        if (this.baseInfoForm.taskId) {
          this.getAssociatedBills();
        }
        this.inspectionConfigs = form.inspectionConfigs;
        // this.$nextTick(() => {
        this.inspectionProject = form.inspectionConfigs.map(item => {
          return item.configId;
        });
        // });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //获取关联单据
    async getAssociatedBills() {
      try {
        this.loading = true;
        let res = await associatedBills({
          taskId: this.baseInfoForm.taskId
        });
        //基本信息
        this.jsonData = res.data;
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //查询质检配置列表
    async getInspectionReq() {
      try {
        this.loading = true;
        let res = await getInspection();
        this.handleTreeData(res.data);
        this.getDetail();
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //处理树形结构
    handleTreeData(data) {
      // 第二种 修改涉及级联选择器 最后一个空白的情况
      // 循环遍历json数据
      for (var i = 0; i < data.length; i++) {
        data[i]["label"] = data[i].configValue;
        data[i].pid = data[i].parentId ? data[i].parentId : 0;
        data[i].id = data[i].configId;
        if (data[i].children && data[i].children.length) {
          this.handleTreeData(data[i].children);
        }
      }
      this.treeData = data;
    },
    //点击确定
    confirm() {
      if (this.baseInfoForm.qualityStatusCode == 1) {
        //未质检
        this.$refs.inspectionForm.validate(valid1 => {
          if (valid1) {
            this.confirmServiceQTReq();
          }
        });
      } else {
        //已质检未审核
        this.$refs.inspectionForm.validate(valid1 => {
          this.$refs.approveForm.validate(valid2 => {
            if (valid1 && valid2) {
              //已质检未审核
              this.approveServiceQTReq();
            }
          });
        });
      }
    },
    //确认质检
    async confirmServiceQTReq() {
      try {
        this.loading = true;
        let res = await confirmServiceInspection({
          ...this.inspectionForm,
          inspectionId: this.inspectionId,
          inspectionProject: this.inspectionProject
        });
        if (res.code == "OK") {
          this.$message.success("质检成功");
          this.inspectionForm = {
            qualityStatusCode: "", //质检结果
            qualityAdvice: "" //质检建议
          };
          this.$refs.inspectionForm.resetFields();
          this.routerPush({
            name: "qualityInspectionCenter/customerServiceQuality",
            params: {
              refresh: false
            }
          });
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //审核质检
    async approveServiceQTReq() {
      try {
        this.loading = true;
        let res = await confirmAuditInspection({
          ...this.inspectionForm,
          ...this.approveForm,
          inspectionId: this.inspectionId,
          inspectionProject: this.inspectionProject
        });
        if (res.code == "OK") {
          this.$message.success("审核成功");
          this.inspectionForm = {
            qualityStatusCode: "", //质检结果
            qualityAdvice: "" //质检建议
          };
          this.approveForm = {
            reviewStatusCode: "", //审核结果
            reviewAdvice: "" //审核建议
          };
          this.$refs.inspectionForm.resetFields();
          this.$refs.approveForm.resetFields();
          this.routerPush({
            name: "qualityInspectionCenter/customerServiceQuality",
            params: {
              refresh: false
            }
          });
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //播放录音
    playVideo() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.tree-data {
  margin-left: 20px;
  margin-right: 20px;
  box-sizing: border-box;
  padding: 20px 0px;
  height: 350px;
  border-bottom: 1px solid #ebeef5;
}
.score-data {
  margin-top: 20px;
  padding-left: 45px;
  padding-right: 45px;
  box-sizing: border-box;
  display: flex;

  // justify-content: space-between;
  .left-tree {
    width: 60%;
    height: 410px;
  }
  .right-tree {
    margin-left: 20px;
    width: 38%;
    height: 436px;
    overflow-y: auto;
  }
  .tree-title {
    margin-bottom: 10px;
    font-size: 14px;
    color: #666;
  }
  .total-title {
    margin-left: 120px;
  }
  .total-score {
    color: #ec9e3c;
    font-size: 18px;
    font-weight: 550;
  }
  .right-tree {
    .item-tree {
      margin-bottom: 10px;
      font-size: 14px;
      color: #666;
    }
  }
}
.advice-box {
  margin-top: 25px;
  display: flex;
  & > span {
    flex-shrink: 0;
    margin-top: 10px;
  }
  .el-textarea {
    width: 635px;
    margin-left: 15px;
    min-height: 70px;
    .el-textarea__inner {
      min-height: 70px !important;
    }
  }
}
/deep/ .baseInfo-box {
  .el-form {
    .el-form-item {
      margin-bottom: 5px;
    }
  }
}
/deep/.inspectionForm {
  .el-textarea {
    width: 400px;
  }
}
// /deep/.e6-tree {
//   height: 410px;
// }
</style>
